<div class="flex flex-col gap-y-4 m-8">
	<div [innerHTML]="'tools.tool.obs-wheel.introduction' | translate"></div>
	<mat-card>
		<mat-card-content>
			<div class="flex flex-col">
				<div class="flex flex-row gap-x-4 w-full">
					<mat-form-field
						appearance="outline"
						class="flex flex-1">
						<mat-label>{{ getDateLabel() }}</mat-label>
						<input
							matInput
							disabled
							(dateChange)="updateDate()"
							[(ngModel)]="date"
							[matDatepicker]="picker1" />
						<mat-datepicker-toggle
							matIconSuffix
							[for]="picker1"></mat-datepicker-toggle>
						<mat-datepicker
							#picker1
							disabled="false"></mat-datepicker>
					</mat-form-field>
					<button
						mat-stroked-button
						color="primary"
						(click)="chooseDate()">
						<mat-icon>tune</mat-icon>
					</button>
				</div>
				<div
					*ngIf="ddr !== null; else noDate"
					class="flex flex-col gap-y-8">
					<div class="flex flex-col items-center text-lg">
						<div>
							{{ "tools.tool.obs-wheel.we-are" | translate }} {{ currentDate | date: "dd/MM/yyyy" }}
						</div>
						<div>Terme à ce jour : {{ sa }}SA + {{ days }} jour{{ days > 1 ? "s" : "" }}</div>
					</div>
					<div class="flex flex-row gap-x-4">
						<div class="flex flex-1 flex-col items-center">
							<div class="font-bold">Echo T1</div>
							<div>à réaliser entre 11SA et 13SA + 6jours</div>
							<div class="text-blue-600">
								{{ "core.from" | translate }} {{ t1From | date: "dd/MM/yyyy" }}
								{{ "core.to" | translate }} {{ t1To | date: "dd/MM/yyyy" }}
							</div>
						</div>
						<div class="flex flex-1 flex-col items-center">
							<div class="font-bold">Echo T2</div>
							<div>à réaliser entre 20SA et 23SA + 6jours</div>
							<div class="text-blue-600">
								{{ "core.from" | translate }} {{ t2From | date: "dd/MM/yyyy" }} au
								{{ t2To | date: "dd/MM/yyyy" }}
							</div>
						</div>
					</div>
					<div class="flex flex-row gap-x-4">
						<div class="flex flex-1 flex-col items-center justify-center">
							<div class="font-bold">Echo T3</div>
							<div>à réaliser entre 30SA et 33SA + 6jours</div>
							<div class="text-blue-600">
								{{ "core.from" | translate }} {{ t3From | date: "dd/MM/yyyy" }} au
								{{ t3To | date: "dd/MM/yyyy" }}
							</div>
						</div>
						<div class="flex flex-1 justify-center">
							<div class="flex flex-row items-center gap-x-4">
								<img
									ngSrc="assets/images/deadline.png"
									alt="deadline"
									height="64"
									width="64" />
								<div class="flex flex-1 flex-col items-center">
									<div class="font-bold text-blue-600">Terme (41SA)</div>
									<div class="font-bold text-blue-600">{{ term | date: "dd/MM/yyyy" }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ng-template #noDate>
					<div class="flex flex-col items-center gap-y-4">
						<img
							ngSrc="../../../../../assets/images/robot/ng_robot_yoga.png"
							alt="robot yoga"
							height="448"
							width="323" />
						<div class="text-xl">
							{{ "tools.tool.obs-wheel.instructions.fill-dates" | translate }}
						</div>
					</div>
				</ng-template>
			</div>
		</mat-card-content>
	</mat-card>
</div>
