import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
} from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { MatRadioButton, MatRadioChange, MatRadioGroup } from "@angular/material/radio";
import { EObstetricWheelDateOption } from "../../../../enums/eobstetric-wheel-date-option";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-obstetric-wheel-date-otions",
	imports: [
		MatDialogClose,
		MatDialogContent,
		MatRadioGroup,
		MatRadioButton,
		MatDialogActions,
		MatButton,
		NgxTolgeeModule,
	],
	templateUrl: "./obstetric-wheel-date-otions.component.html",
	styleUrl: "./obstetric-wheel-date-otions.component.scss",
})
export class ObstetricWheelDateOtionsComponent {
	option: string = "ddr";
	protected readonly EObstetricWheelDateOption = EObstetricWheelDateOption;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { option: EObstetricWheelDateOption },
		private dialogRef: MatDialogRef<ObstetricWheelDateOtionsComponent>
	) {
		this.option = data.option;
	}

	updateDateOption($event: MatRadioChange): void {
		this.dialogRef.close($event.value);
	}
}
