import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogTitle,
} from "@angular/material/dialog";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatFormField, MatHint, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { EScoreType, Score } from "../../../../entities/tools/score/score";
import { MatIcon } from "@angular/material/icon";
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelActionRow,
	MatExpansionPanelDescription,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { AddScoreReferenceComponent } from "../add-score-reference/add-score-reference.component";
import { ScoreReference } from "../../../../entities/tools/score/score-reference";
import { ScoreService } from "../../../../services/score.service";
import { ToastrService } from "ngx-toastr";
import { finalize, first } from "rxjs";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { ScoreCriterion } from "../../../../entities/tools/score/score-criterion";
import { AddScoreCriterionComponent } from "../add-score-criterion/add-score-criterion.component";
import { MatDivider } from "@angular/material/divider";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";

@Component({
	selector: "app-create-score",
	imports: [
		MatDialogTitle,
		NgxTolgeeModule,
		MatDialogContent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatFormField,
		MatInput,
		FormsModule,
		MatLabel,
		MatIcon,
		MatPrefix,
		MatExpansionPanel,
		MatExpansionPanelDescription,
		MatIconButton,
		MatAccordion,
		MatExpansionPanelTitle,
		MatExpansionPanelHeader,
		MatExpansionPanelActionRow,
		MatProgressSpinner,
		NgIf,
		MatDivider,
		EditorComponent,
		MatOption,
		MatSelect,
		MatHint,
	],
	templateUrl: "./create-score.component.html",
	styleUrl: "./create-score.component.scss",
})
export class CreateScoreComponent {
	score: Score = new Score();
	isLoading = false;
	editorConfig = {
		base_url: "/tinymce",
		height: "100%",
		language: "fr_FR",
		promotion: false,
		branding: false,
		license_key: "gpl",
		language_url: "./assets/tinymce/fr_FR.js",
		menubar: false,
		statusbar: false,
		plugins: [
			"advlist",
			"autolink",
			"lists",
			"link",
			"image",
			"charmap",
			"anchor",
			"searchreplace",
			"visualblocks",
			"visualchars",
			"code",
			"nonbreaking",
			"table",
			"directionality",
		],
		toolbar1: "bold italic underline forecolor | alignleft aligncenter alignright alignjustify | outdent indent ",
		toolbar2: "insert charmap hr table code",
		image_advtab: true,
		automatic_uploads: true,
		browser_spellcheck: true,
		table_default_styles: {
			"border-color": "#000000",
			width: "100%",
			"margin-left": "auto",
			"margin-right": "auto",
		},
		table_default_attributes: { border: 1, cellspacing: 0 },
		nonbreaking_force_tab: false,
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: { score: Score },
		private dialog: MatDialog,
		private scoreService: ScoreService,
		private toastr: ToastrService,
		private translate: TranslateService
	) {
		if (this.data.score) {
			this.score = this.data.score;
		}
	}

	create(): void {
		this.isLoading = true;
		this.scoreService
			.create(this.score)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((response: Score) => {
				this.toastr.success(this.translate.instant("tools.score.create.success"));
				this.score = response;
			});
	}

	updateScore(): void {
		this.isLoading = true;
		this.scoreService
			.update(this.score)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((response: Score) => {
				this.toastr.success(this.translate.instant("tools.score.update.success"));
				this.score = response;
			});
	}

	addReference(): void {
		this.dialog
			.open(AddScoreReferenceComponent, {
				data: { score: this.score, reference: null },
				width: "600px",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((score: Score) => {
				if (score) {
					this.score = score;
				}
			});
	}

	addCriterion(): void {
		this.dialog
			.open(AddScoreCriterionComponent, {
				data: { score: this.score, criterion: null },
				width: "600px",
				disableClose: true,
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((score: Score) => {
				if (score) {
					this.score = score;
				}
			});
	}

	deleteReference(reference: ScoreReference): void {
		this.isLoading = true;
		this.scoreService
			.deleteReference(this.score, reference)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((response) => {
				if (response.status === 204) {
					this.toastr.success(this.translate.instant("tools.score.reference.delete.success"));
					this.score.reference = this.score.reference.filter((r) => r.id !== reference.id);
				} else {
					this.toastr.error(this.translate.instant("core.message.error.unknown"));
				}
			});
	}

	deleteCriterion(item: ScoreCriterion): void {
		this.isLoading = true;
		this.scoreService
			.deleteCriterion(this.score, item)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((response) => {
				if (response.status === 204) {
					this.toastr.success(this.translate.instant("tools.score.criterion.delete.success"));
					this.score.criteria = this.score.criteria.filter((c) => c.id !== item.id);
				}
			});
	}

	protected readonly EScoreType = EScoreType;
}
