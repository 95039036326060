import { Component, Input } from "@angular/core";
import { EScoreType, Score } from "../../../../entities/tools/score/score";
import { MatDivider } from "@angular/material/divider";
import { NgIf } from "@angular/common";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { ScoreCriterion } from "../../../../entities/tools/score/score-criterion";
import { SafeHtmlPipe } from "../../../../pipes/safe-html.pipe";
import { MatCheckbox } from "@angular/material/checkbox";
import { ScoreService } from "../../../../services/score.service";

@Component({
	selector: "app-score-viewer",
	imports: [MatDivider, NgIf, MatRadioButton, MatRadioGroup, SafeHtmlPipe, MatCheckbox],
	templateUrl: "./score-viewer.component.html",
	styleUrl: "./score-viewer.component.scss",
})
export class ScoreViewerComponent {
	_selectedScore: Score;
	result: number = null;

	constructor(private scoreService: ScoreService) {}

	@Input() set selectedScore(score: Score) {
		this._selectedScore = score;
		this.onSelectedScoreChange();
	}

	private onSelectedScoreChange(): void {
		this.result = null;
		this.scoreService.clearValues();
	}

	calculate(value: number, criterion: ScoreCriterion, isAdd: boolean = true): void {
		this.result = this.scoreService.calculate(this._selectedScore, value, criterion, isAdd);
	}

	protected readonly EScoreType = EScoreType;
}
