<div mat-dialog-content>
	<div class="flex flex-col">
		<mat-radio-group
			[value]="option"
			(change)="updateDateOption($event)">
			<mat-radio-button [value]="EObstetricWheelDateOption.DDR">Date de dernières règles</mat-radio-button>
			<mat-radio-button [value]="EObstetricWheelDateOption.DDG">Date de début de grossesse</mat-radio-button>
			<mat-radio-button [value]="EObstetricWheelDateOption.TERM">Date de terme</mat-radio-button>
		</mat-radio-group>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
