import { Component } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { FormsModule } from "@angular/forms";
import { DatePipe, NgIf, NgOptimizedImage } from "@angular/common";
import { MatCard, MatCardContent } from "@angular/material/card";
import { ObstetricWheelService } from "../../../../services/obstetric-wheel.service";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatDialog } from "@angular/material/dialog";
import { ObstetricWheelDateOtionsComponent } from "../obstetric-wheel-date-otions/obstetric-wheel-date-otions.component";
import { EObstetricWheelDateOption } from "../../../../enums/eobstetric-wheel-date-option";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-obstetric-wheel",
	imports: [
		MatDatepickerModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		DatePipe,
		MatCard,
		MatCardContent,
		NgIf,
		NgOptimizedImage,
		MatButton,
		MatIcon,
		NgxTolgeeModule,
	],
	providers: [provideNativeDateAdapter()],
	templateUrl: "./obstetric-wheel.component.html",
	styleUrl: "./obstetric-wheel.component.scss",
})
export class ObstetricWheelComponent {
	dateOption = EObstetricWheelDateOption.DDR;
	ddr: Date = null;
	date: Date = null;
	currentDate: Date = new Date();
	sa: number = null;
	days: number = null;
	t1From: Date = null;
	t1To: Date = null;
	t2From: Date = null;
	t2To: Date = null;
	t3From: Date = null;
	t3To: Date = null;
	term: Date = null;

	constructor(
		private service: ObstetricWheelService,
		private dialog: MatDialog,
		private translate: TranslateService
	) {}

	updateDate() {
		switch (this.dateOption) {
			case EObstetricWheelDateOption.TERM:
				this.ddr = this.service.termToDdr(this.date);
				break;
			case EObstetricWheelDateOption.DDG:
				this.ddr = this.service.getDdr(this.date);
				break;
			case EObstetricWheelDateOption.DDR:
				this.ddr = this.date;
				break;
		}
		this.calculate();
	}

	calculate(): void {
		const diffDays = this.service.getDiffDays(new Date(), this.ddr);
		this.sa = Math.floor(diffDays / 7);
		this.days = diffDays % 7;

		const t1 = this.service.getT1(this.ddr);
		this.t1From = t1.from;
		this.t1To = t1.to;

		const t2 = this.service.getT2(this.ddr);
		this.t2From = t2.from;
		this.t2To = t2.to;

		const t3 = this.service.getT3(this.ddr);
		this.t3From = t3.from;
		this.t3To = t3.to;

		this.term = this.service.getTerm(this.ddr);
	}

	chooseDate(): void {
		this.dialog
			.open(ObstetricWheelDateOtionsComponent, {
				data: { option: this.dateOption },
				autoFocus: false,
				width: "400px",
			})
			.afterClosed()
			.subscribe((result: EObstetricWheelDateOption) => {
				if (result) {
					this.dateOption = result;
					this.updateDate();
					this.calculate();
				}
			});
	}

	getDateLabel(): string {
		let id = "tools.tool.obs-wheel.ddr";
		switch (this.dateOption) {
			case EObstetricWheelDateOption.DDG:
				id = "tools.tool.obs-wheel.ddg";
				break;
			case EObstetricWheelDateOption.TERM:
				id = "tools.tool.obs-wheel.term";
				break;
		}
		return this.translate.instant(id);
	}
}
