import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { MatButton } from "@angular/material/button";
import { MatFormField, MatHint, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { ScoreReference } from "../../../../entities/tools/score/score-reference";
import { FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { ScoreService } from "../../../../services/score.service";
import { ToastrService } from "ngx-toastr";
import { Score } from "../../../../entities/tools/score/score";
import { finalize, first } from "rxjs";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";

@Component({
	selector: "app-add-score-reference",
	imports: [
		MatDialogTitle,
		NgxTolgeeModule,
		MatDialogContent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatFormField,
		MatIcon,
		FormsModule,
		MatInput,
		MatLabel,
		MatPrefix,
		MatProgressSpinner,
		NgIf,
		MatHint,
	],
	templateUrl: "./add-score-reference.component.html",
	styleUrl: "./add-score-reference.component.scss",
})
export class AddScoreReferenceComponent {
	reference = new ScoreReference();
	isLoading = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: { score: Score; reference: ScoreReference },
		private scoreService: ScoreService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private dialogRef: MatDialogRef<AddScoreReferenceComponent>
	) {}

	add(): void {
		this.isLoading = true;
		this.scoreService
			.addReference(this.data.score, this.reference)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((response: ScoreReference) => {
				this.toastr.success(this.translate.instant("tools.score.reference.add.success"));
				this.dialogRef.close(response);
			});
	}
}
