import { Injectable } from "@angular/core";
import { FromToDate } from "../entities/from-to-date";

@Injectable({
	providedIn: "root",
})
export class ObstetricWheelService {
	getT1(date: Date): FromToDate {
		return { from: this.addDays(date, 77), to: this.addDays(date, 97) };
	}

	getT2(date: Date): FromToDate {
		return { from: this.addDays(date, 140), to: this.addDays(date, 167) };
	}

	getT3(date: Date): FromToDate {
		return { from: this.addDays(date, 210), to: this.addDays(date, 237) };
	}

	getTerm(date: Date): Date {
		return this.addDays(date, 287);
	}

	getDdg(ddr: Date): Date {
		return this.addDays(ddr, 14);
	}

	getDdr(ddg: Date): Date {
		return this.addDays(ddg, -14);
	}

	addDays(date: Date, days: number): Date {
		const newDate = new Date(date);
		newDate.setDate(newDate.getDate() + days);
		return newDate;
	}

	getDiffDays(date1: Date, date2: Date): number {
		const diff = Math.abs(date1.getTime() - date2.getTime());
		return Math.round(diff / (1000 * 3600 * 24));
	}

	termToDdr(term: Date): Date {
		return this.addDays(term, -287);
	}
}
